@import "core";
.listing-wrap {
	padding: 5.9rem 0 9.3rem;

	.listing-title {
		font-size: 6rem;

		@include media {
			font-size: 4rem;
		}
	}

	.listing-filters {
		position: relative;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 2.4rem;
		margin-bottom: 5rem;
		border-bottom: .1rem solid $color-border;
		padding-bottom: 2.2rem;

		@include media {
			display: block;
			margin-bottom: 2.4rem;
		}
	}

		.filters-left {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			@include mediaMin {
				> * + * {
					margin-left: 2rem;
				}
			}

			@include media {
				flex-wrap: wrap;
				> * + * {
					margin-top: 2rem;
				}
			}
		}

		.filters-city, .filters-branch {
			width: 23rem;

			@include media {
				width: 100%;
			}
		}

		.filters-sorting {
			width: 18rem;

			@include media {
				width: 100%;
				margin-top: 2rem;
			}
		}

	// .listing-results {
	// }

		.results-events {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 2.7rem 2.1rem;

			@include media {
				grid-template-columns: 1fr;
			}
		}

		.results-pagination {
			margin-top: 5.7rem;
		}
}