@import "core";
.pagination {
	display: flex;
	align-items: center;
	justify-content: center;

	@include media {
		justify-content: space-between;
	}

	.pagination-nums {
		margin: 0 1rem;
		display: flex;
		justify-content: center;

		@include media {
			margin: 0;
		}
	}

		.nums-display {
			position: relative;
			cursor: pointer;
			padding: 1rem;
		}

			.display-select {
				position: absolute;
				opacity: 0;
				left: 0;
				width: 100%;
				height: 100%;
				top: 0;
			}

	.pagination-seperator {
		line-height: 3.5rem;
		margin: 0 .5rem;
		color: $color-text-light;
	}

	.pagination-navbtn {
		width: 16rem;
	
		@include media {
			width: auto;
		}

		.icon {
			font-size: 1rem;
			margin-top: -.2rem;
		}
		&.prev {
			.icon {
				margin-right: 1rem;
			}
		}
		&.next {
			.icon {
				margin-left: 1rem;
			}
		}
	}

	.pagination-button {
		@include font-semibold;
		min-width: 4rem;
		height: 3.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: .5rem;
		transition: color $transition-hover ease, background-color $transition-hover ease;

		> * + * {
			margin-left: 1rem;
		}
		
		&.nav {
			padding-left: 2rem;
			padding-right: 2rem;
			border: .1rem solid $color-primary;

			&:disabled {
				opacity: .5;
			}

			@include mediaMin {
				&:not(:disabled) {
					&:hover {
						background-color: $color-bg;
						color: $color-text;
					}
				}
			}
		}

		&.active {
			background-color: $color-primary;
			color: $color-white;
			border-radius: $radius-general;
		}

		@include mediaMin {
			&:not(.active) {
				&:hover {
					color: $color-primary;
				}
			}
		}
	}
}